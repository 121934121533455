<script>
import useInspection from '@/stores/useInspection'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import commonFilters from '../../../common/commonFilters'
import RadioGroupSlot from './RadioGroupSlot'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const inspectionStore = useInspection()
    return {
      loadingStore,
      inspectionStore,
    }
  },
  components : {
    RadioGroupSlot
  },
  mixins : [commonFilters],
  props  : {},
  data() {
    return {
    }
  },
  computed : {
    commonData() {
      return this.inspectionStore.commonData
    },
    identityFiles() {
      return this.inspectionStore.identityFiles
    },
    authLetterFiles() {
      return this.inspectionStore.authLetterFiles
    },
    errors() {
      return this.inspectionStore.errors
    },
    constants() {
      return this.inspectionStore.constants
    },
    manageNo() {
      return this.inspectionStore.manageNo
    }
  },
  methods : {
    isNumber(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      }
      return true
    },
  },
})
</script>
<template>
<div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP1_REQUEST_NO') }}</p>
    <div class="form-wrap-input">
      <p>{{ manageNo || $t('INSPECTION_FORM_MANAGE_NO_NULL') }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP1_REQUEST_TITLE') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex align-center">
      <input
        type="text"
        class="iptW-M full"
        v-model="inspectionStore.requestTitle"
        v-bind:class="{'err': errors.requestTitle}"
        @keydown="errors.requestTitle=null"
        ref="requestTitle"
        name="requestTitle"
      >
      <p class="inps-err-txt">{{errors.requestTitle}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP1_ESTIMATE_PRICE') }}</p>
    <div class="form-wrap-input subtextWrap">
      <input
        type="text"
        class="iptW-S text-right"
        v-model="commonData.estimatePrice"
        v-bind:class="{'err': errors.estimatePrice}"
        v-on:keypress="isNumber($event)"
        v-on:focusin="($event) => commonData.estimatePrice = $options.filters.subComma($event.target.value)"
        v-on:focusout="($event) => commonData.estimatePrice = $options.filters.addComma($event.target.value)"
        @keydown="errors.estimatePrice=null"
        maxlength="8"
        ref="estimatePrice"
        name="estimatePrice"
      >
      <span class="ipt-rule">円</span>
      <!-- <p class="err-txt">{{errors.estimatePrice}}</p> -->
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP1_NAME') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex align-center">
      <input
        type="text"
        class="iptW-M"
        v-model="commonData.name"
        v-bind:class="{'err': errors.name}"
        @keydown="errors.name=null"
        ref="name"
        name="name"
      >
      <p class="inps-err-txt">{{errors.name}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP1_TEL') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex align-center">
      <input
        type="text"
        class="ime-dis iptW-M"
        v-model="commonData.tel"
        v-bind:class="{'err': errors.tel}"
        @keydown="errors.tel=null"
        ref="tel"
        name="tel"
      >
      <p class="inps-err-txt">{{errors.tel}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP1_ADDRESS') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex align-center">
      <input
        type="text"
        class="iptW-M"
        v-model="commonData.address"
        v-bind:class="{'err': errors.address}"
        @keydown="errors.address=null"
        ref="address"
        name="address"
      >
      <p class="inps-err-txt">{{errors.address}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap align-start">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP1_IDENTITY_VERIFY') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input">
      <RadioGroupSlot
        name="identity_file"
        v-slot="{isDisabled}"
        :model="commonData.identity_file"
        :options="constants.SELECT_IDENTITY_FILE || []"
        :isErrorRadio="errors.identity_file"
        :errTxtNextToRadio="true"
        @onChangeModelValue="({val, isTextInput}) => {commonData.identity_file = val; errors.identity_file=null; errors.identityFilePath=null}"
      >
        <div class="ipt-file ipt-file-box" v-for="(file, index) in identityFiles" :key="index">
          <label @click="identityFiles.splice(index, 1)" :disabled="isDisabled">{{$t('MEMBER_REQUEST_FORM_FILE_DELETE_BUTTON_LABEL')}}</label>
          <p class="ipt-file-txt">{{file.name}}</p>
        </div>
        <div class="ipt-file ipt-file-box" v-if="identityFiles.length < 1">
          <label v-bind:class="{'err': errors.identityFilePath}" @click="errors.identityFilePath=null">
            <input type="file" :disabled="isDisabled" ref="identityFilePath"
              @change="(event) => inspectionStore.addIdentityFile(event, $t('MY_PAGE_UPDATE_FILE_SIZE_LIMIT_ERROR_MESSAGE'))">{{$t('INSPECTION_DOCUMENT_FILE_SELECT')}}
          </label>
          <p class="ipt-file-txt" v-html="$t('INSPECTION_STEP1_FILE_NOT_SELECTED')"></p>
          <!-- <p class="err-txt">{{errors.identityFilePath}}</p> -->
        </div>
      </RadioGroupSlot>
    </div>
  </div>
</div>
</template>
<style scoped lang="scss">
  .ipt-file-box {
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 768px) {
    p.err-txt {
      position: absolute;
      top: 50%;
      left: 450px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      max-width: 290px;
    }
    .textarea-content {
      background-color: white;
      width:430px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    p.err-txt {
        position: static;
        -webkit-transform: none;
        transform: none;
        max-width: 100%;
        margin-top: 5px;
    }
  }

  .with-int-no {
    width: 305px !important;
  }
  select.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  label.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  .bold-text {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
</style>
<style lang="scss" scoped>
  .est-form {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #main .exhibit #entry-form .form-wrap .ipt-file {
    width: auto !important;
  }
  #main .exhibit #entry-form .form-wrap-input .inps-err-txt {
    position: relative;
    margin-left: 10px;
    color: #f00
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
