<template>
<main id="main">
  <section id="exhibit" class="exhibit">
    <InspectionForm :parent="$data" v-if="editFlag"/>
    <Completion v-else-if="completedFlag" :parent="$data" />
    <ComfirmForm v-else
      :manageNo="manageNo"
      :requestTitle="requestTitle"
      :comment="comment"
      :commonData="commonData"
      :identityFiles="identityFiles"
      :bodyTypes="bodyTypes"
      :constants="constants"
      :getPreviewData="inspectionStore.getPreviewData"
      :imageItems="itemImages"
      :imageOptionals="optionalImages"
      :documentFiles="documentFiles"
      :errors="errors"
      @goBack="() => {inspectionStore.ruleCheckBtnClicked = false; inspectionStore.editFlag = true}"
      @goNext="requestItem"
    />
  </section>
</main>
</template>

<script>
import Methods from '@/api/methods'
import useInspection from '@/stores/useInspection'
import useLoading from '@/stores/useLoading'
import useRequestResultPreview from '@/stores/useRequestResultPreview'
import {defineComponent} from '@vue/composition-api'
import ComfirmForm from '../../components/Inspection/Comfirm'
import Completion from '../../components/Inspection/Completion'
import InspectionForm from '../../components/Inspection/Form'
export default defineComponent({
  setup() {
    const inspectionStore = useInspection()
    const resultPreviewStore = useRequestResultPreview()
    const loadingStore = useLoading()
    return {
      inspectionStore,
      resultPreviewStore,
      loadingStore
    }
  },
  components : {
    InspectionForm,
    ComfirmForm,
    Completion
  },
  data() {
    return {
    }
  },
  computed : {
    editFlag() {
      return this.inspectionStore.editFlag
    },
    completedFlag() {
      return this.inspectionStore.completedFlag
    },
    manageNo() {
      return this.inspectionStore.manageNo
    },
    requestTitle() {
      return this.inspectionStore.requestTitle
    },
    comment() {
      return this.inspectionStore.comment
    },
    commonData() {
      return this.inspectionStore.commonData
    },
    identityFiles() {
      return this.inspectionStore.identityFiles
    },
    bodyTypes() {
      return this.inspectionStore.bodyTypes
    },
    itemImages() {
      return this.inspectionStore.itemImages
    },
    optionalImages() {
      return this.inspectionStore.optionalImages
    },
    documentFiles() {
      return this.inspectionStore.documentFiles
    },
    constants() {
      return this.inspectionStore.constants
    },
    errors() {
      return this.inspectionStore.errors
    }
  },
  mounted() {
    const itemRequestNo = this.$route.params ? this.$route.params.id : null
    if (itemRequestNo) {
      this.inspectionStore.memberCheck(itemRequestNo, this.$i18n.locale)
        .then(() => {
          this.inspectionStore.getConstants(itemRequestNo, this.$i18n.locale)
          this.resultPreviewStore.getResult(itemRequestNo, this.$i18n.locale)
        })
        .catch(error => {
          console.log('error', error)
          this.loadingStore.setLoading(false)
          this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
          this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.MEMBER_NO)
          this.$routerGo(this.$define.PAGE_NAMES.LOGIN_PAGE_NAME)
        })
    } else {
      this.inspectionStore.getConstants(itemRequestNo, this.$i18n.locale)
      this.resultPreviewStore.getResult(itemRequestNo, this.$i18n.locale)
    }
  },
  created() {
    // If (this.completedFlag) {
    this.inspectionStore.resetData()
    // }
  },
  methods : {
    requestItem() {
      this.inspectionStore.requestItem()
        .then(() => {
          window.scrollTo({
            top      : 0,
            left     : 0,
            behavior : 'smooth'
          })
        })
        .catch(error => {
          console.log('error', error)
          this.loadingStore.setLoading(false)
          this.inspectionStore.errors = Object.assign(
            Methods.parseHtmlResponseError(this.$router, error),
            this.inspectionStore.errors
          )
        })
    }
  }
})
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
