<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom mt-5 popup popup-keep" style="display: block;">
      <div class="popup-wrap small">
        <a class="popup-close js-close">
          <img @click.stop="noClick" src="@/assets/img/common/ic_close.svg" alt="">
        </a>
        <div class="popup-content">
          <p class="popup-content-msg">{{ $t('INSPECTION_AUCTION_REQUEST_SAVE_SUCCESS_TXT') }}</p>
          <div class="popup-content-btnWrap">
            <button @click="noClick" class="popup-content-btn normal js-close" type="submit">
              {{ $t('INSPECTION_AUCTION_REQUEST_SAVE_SUCCESS_CLOSE') }}
            </button>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import {defineComponent} from '@vue/composition-api'
export default defineComponent({
  components : {},
  props      : {
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
  },
  data() {
    return {
    }
  },
  computed : {
  },
  watch : {
  },
  created() {
  },
  destroyed() {
    console.log('destroyed')
  },
  methods : {
    okClick() {
      console.log('okClick')
      this.$emit('next')
    },
    noClick() {
      console.log('noClick')
      this.$emit('close')
    },
  }
})
</script>
<style lang="scss" scoped>
  .popup-content-form-wrap input {
    background: #fff !important;
  }
  .popup-content-form-wrap p.err-txt {
    font-size: 12px;
  }
  .popup-content-btn:first-of-type {
    margin-top: 20px !important;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
