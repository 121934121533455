<script>
import useInspection from '@/stores/useInspection'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const inspectionStore = useInspection()
    return {
      loadingStore,
      inspectionStore,
    }
  },
  props : {
    model : {
      type : Number,
    },
    label : {
      type    : String,
      default : null,
    },
    options : {
      type    : Array,
      default : [],
    },
    isErrorRadio : {
      type    : String,
      default : null
    },
    errTxtNextToRadio : {
      type    : Boolean,
      default : false
    }
  },
  data() {
    return {
      errors : {
        content : null,
      },
    }
  },
  computed : {
    isDisabled() {
      return !(
        (this.options.find(x => x.value === this.model) || {}).isTextInput
        === true
      )
    },
  },
  methods : {
    onChangeModelValue(val) {
      console.log('onChangeModelValue', val)
      const isTextInput = (this.options.find(x => x.value === val) || {}).isTextInput
      this.$emit('onChangeModelValue', {val, isTextInput})
    },
  },
})
</script>

<template>
  <div class="ins-radio-group">
  <v-radio-group :value="model" @change="onChangeModelValue" :error="!!isErrorRadio" messages="1" row>
    <template v-slot:label>
      <div v-if="label" class="form-wrap-radioFlex-tit">{{ label }}</div>
    </template>
    <v-radio
      v-for="(val, index) in options"
      :key="index"
      :label="val.label"
      :value="val.value"
    ></v-radio>
    <label v-if="errTxtNextToRadio && isErrorRadio" class="inps-err-txt">{{ isErrorRadio }}</label>
    <template v-slot:message>
      <div v-bind:class="{'disabled': isDisabled}">
        <slot :isDisabled="isDisabled"></slot>
      </div>
    </template>
  </v-radio-group>
  </div>
</template>
<style scoped lang="scss">
.disabled {
  opacity: 0.5;
  // background: #d7dadb;
  cursor: pointer !important;
}
.ins-radio-group {
  margin: 0 auto 5px;
}
.inps-err-txt {
  position: relative;
  margin-left: 10px;
  color: #f00;
  font-size: 18px;
  font-weight: normal;
}
::v-deep .v-messages__message {
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .ins-radio-group {
    width: 100%;
  }
  .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 8px;
  }
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
