<script>
import Methods from '@/api/methods'
import useInspection from '@/stores/useInspection'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import CancelDialog from './CancelDialog'
import PopupSlot from './PopupSlot'
import SavedSuccessDialog from './SavedSuccessDialog'

export default defineComponent({
  components : {
    CancelDialog,
    SavedSuccessDialog,
    PopupSlot
  },
  setup() {
    const loadingStore = useLoading()
    const inspectionStore = useInspection()
    return {
      loadingStore,
      inspectionStore,
    }
  },
  props : {
  },
  data() {
    return {
      cancelModal       : false,
      errorModal  : false
    }
  },
  computed : {
    ruleCheckBtnClicked() {
      return this.inspectionStore.ruleCheckBtnClicked
    },
    saveTemporaryComplete() {
      return this.inspectionStore.saveTemporaryComplete
    }
  },
  methods : {
    validate(event) {
      event.preventDefault()
      this.inspectionStore.validate()
        .then(() => {
          window.scrollTo({
            top      : 0,
            left     : 0,
            behavior : 'smooth'
          })
        })
        .catch(error => {
          console.log('error', error)
          this.loadingStore.setLoading(false)
          this.inspectionStore.errors = Object.assign(
            Methods.parseHtmlResponseError(this.$router, error),
            this.inspectionStore.errors
          )
          if (this.inspectionStore.errors && Object.keys(this.inspectionStore.errors).length > 0) {
            this.errorModal = true
          }
        })
    },
    saveTemporary(event) {
      console.log('saveTemporary')
      event.preventDefault()
      this.inspectionStore.saveTemporary()
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Object.assign(Methods.parseHtmlResponseError(router, error), this.errors)
        })
    },
    saveTemporaryThenGoBack(event) {
      console.log('saveTemporaryThenGoBack')
      // Event.preventDefault()
      this.inspectionStore.saveTemporary()
        .then(() => {
          this.$routerGo(this.$define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)
        })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Object.assign(Methods.parseHtmlResponseError(router, error), this.errors)
        })
    },
    deleteTemporaryThenGoBack(event) {
      console.log('deleteTemporaryThenGoBack')
      // Event.preventDefault()
      this.inspectionStore.deleteRequestTemporary()
        .then(() => {
          this.$routerGo(this.$define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)
        })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Object.assign(Methods.parseHtmlResponseError(router, error), this.errors)
        })
    },
    backToList() {
      if (this.inspectionStore.isDataChanged()) {
        this.cancelModal = true
      } else {
        this.$routerGo(this.$define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)
      }
    },
    closeErrorDialog() {
      this.errorModal = false
      this.$nextTick(() => {
        if (this.inspectionStore.errors && Object.keys(this.inspectionStore.errors).length > 0) {
          const keys = Object.keys(this.inspectionStore.errors)
          const refkey = keys[0]
          const elms = document.getElementsByName(refkey)
          if (elms && elms.length > 0) {
            elms[0].scrollIntoView()
          }
        }
      })
    }
  }
})
</script>

<template>
  <div class="btnWrap">
    <button @click="backToList" class="formBtn reset">登録を中止して<br>一覧に戻る</button>
    <button @click="saveTemporary" class="formBtn keep js-popup" name="keep">{{ $t('ITEM_REQUEST_FORM_SAVE_BTN') }}</button>
    <button @click="validate" :disabled="!ruleCheckBtnClicked" v-bind:class="{'formBtn': true, 'submit': true, 'disabled': !ruleCheckBtnClicked}">{{ $t('MEMBER_REQUEST_FORM_SUBMIT_LABEL') }}</button>

    <cancel-dialog
      :isOpenDialog="cancelModal"
      @close="() => {cancelModal=false}"
      @ok-save="saveTemporaryThenGoBack"
      @ok-clear="deleteTemporaryThenGoBack"
    />

    <saved-success-dialog
      :isOpenDialog="saveTemporaryComplete"
      @close="() => inspectionStore.saveTemporaryComplete = false"
    />

    <PopupSlot
      :is-open-dialog="errorModal"
      :isShowBtnCancel="false"
      txtOk="閉じる"
      @close="closeErrorDialog"
      @action="closeErrorDialog"
    >
      <p class="text-center">{{ $t('INSPECTION_STEP2_ERROR_DIALOG_TXT') }}</p>
    </PopupSlot>
  </div>
</template>
<style lang="scss" scoped>
  #main .exhibit .btnWrap button.disabled {
    cursor: pointer;
    opacity: 0.5;

    &:hover, &:focus {
      opacity: 0.5;
      cursor: pointer;
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
