<script>
import useInspection from '@/stores/useInspection'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const inspectionStore = useInspection()
    return {
      loadingStore,
      inspectionStore,
    }
  },
  props : {
    model : {
      type : Number,
    },
    modelText : {
      type    : String,
      default : null,
    },
    label : {
      type    : String,
      default : null,
    },
    options : {
      type    : Array,
      default : [],
    },
    isErrorRadio : {
      type    : String,
      default : null
    },
    isErrorOther : {
      type    : String,
      default : null
    },
    refTxt : {
      type    : String,
      default : null,
    },
    placeholder : {
      type    : String,
      default : null,
    },
    errTxtNextToRadio : {
      type    : Boolean,
      default : false
    },
    appendText : {
      type    : String,
      default : null,
    }
  },
  data() {
    return {
      errors : {
        content : null,
      },
    }
  },
  computed : {
    isDisabled() {
      return !(
        (this.options.find(x => x.value === this.model) || {}).isTextInput
        === true
      )
    },
    inputVal : {
      get() {
        return this.model
      },
      set(val) {
        this.$emit('onChangeModelValue', val)
      },
    },
    inputTextVal : {
      get() {
        return this.modelText
      },
      set(val) {
        this.$emit('onChangeModelTextValue', val)
      },
    },
  },
  methods : {
    onChangeModelValue(val) {
      console.log('onChangeModelValue', val)
      const isTextInput = (this.options.find(x => x.value === val) || {}).isTextInput
      this.$emit('onChangeModelValue', {val, isTextInput})
    },
    onChangeModelTextValue(val) {
      this.$emit('onChangeModelTextValue', val)
    },
  },
})
</script>

<template>
  <div class="ins-radio-group">
    <slot name="notice"></slot>
  <v-radio-group :value="model" @change="onChangeModelValue" :error="!!isErrorRadio" messages="1" row>
    <template v-slot:label>
      <div v-if="label" class="form-wrap-radioFlex-tit">{{ label }}</div>
    </template>
    <v-radio
      v-for="(val, index) in options"
      :key="index"
      :label="val.label"
      :value="val.value"
    ></v-radio>
    <label v-if="errTxtNextToRadio && isErrorRadio" class="err-txt">{{ isErrorRadio }}</label>
    <template v-slot:message>
      <div class="custom-ipt">
        <div class="text-field">
          <input
            type="text"
            v-bind:class="{'iptW-S': true, 'disabled': isDisabled, 'err': !!isErrorOther}"
            v-model="inputTextVal"
            :disabled="isDisabled"
            :placeholder="placeholder"
            @keydown="() => $emit('onTextAreaKeydown')"
            :ref="refTxt"
            :name="refTxt+'_other'"
          />
        </div>
        <div class="append">
          <span v-if="appendText">{{ appendText }}</span>
        </div>
      </div>
    </template>
  </v-radio-group>
  </div>
</template>
<style scoped lang="scss">
input.disabled {
  background: #466774;
}
.ins-radio-group {
  margin: 0 auto 5px;
}
input.disabled {
  opacity: 0.5;
  background: #d7dadb;
}

@media only screen and (max-width: 767px) {
  .ins-radio-group {
    width: 100%;
  }
  .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 8px;
  }
}

.custom-ipt {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.custom-ipt .text-field {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}
.custom-ipt .append {
  align-self: flex-end;
  user-select: none;
  margin-left: 5px;
}

.custom-ipt .append span {
    font-size: 1.2rem;
    position: relative;
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
