<script>
import useInspection from '@/stores/useInspection'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const inspectionStore = useInspection()
    return {
      loadingStore,
      inspectionStore,
    }
  },
  props : {
    model : {
      type    : Number,
    },
    modelText : {
      type    : String,
      default : null
    },
    label : {
      type    : String,
      default : null
    },
    placeholder : {
      type    : String,
      default : null
    },
    options : {
      type    : Array,
      default : []
    },
    isErrorRadio : {
      type    : String,
      default : null
    },
    isErrorOther : {
      type    : String,
      default : null
    },
    isRequired : {
      type    : Boolean,
      default : false
    },
    errTxtNextToRadio : {
      type    : Boolean,
      default : false
    },
    refTxt : {
      type    : String,
      default : null
    }
  },
  data() {
    return {
    }
  },
  computed : {
    isDisabled() {
      return !((this.options.find(x => x.value === this.model) || {}).isTextInput === true)
    },
    inputVal : {
      get() {
        return this.model
      },
      set(val) {
        this.$emit('onChangeModelValue', val)
      }
    },
    inputTextVal : {
      get() {
        return this.modelText
      },
      set(val) {
        this.$emit('onChangeModelTextValue', val)
      }
    }
  },
  methods : {
    onChangeModelValue(val) {
      console.log('onChangeModelValue', val)
      const isTextInput = (this.options.find(x => x.value === val) || {}).isTextInput
      this.$emit('onChangeModelValue', {val, isTextInput})
    },
    onChangeModelTextValue(val) {
      this.$emit('onChangeModelTextValue', val)
    }
  }
})
</script>

<template>
  <v-radio-group :value="model" @change="onChangeModelValue" :error="!!isErrorRadio" row>
    <template v-slot:label>
      <div v-if="label" class="form-wrap-radioFlex-tit">{{ label }}</div>
    </template>
    <v-radio v-for="(val, index) in options" :key="index"
      :label="val.label"
      :value="val.value"
    ></v-radio>
    <label v-if="errTxtNextToRadio && isErrorRadio" class="err-txt">{{ isErrorRadio }}</label>
    <textarea
      type="text"
      class="full"
      v-model="inputTextVal"
      :placeholder="placeholder"
      v-bind:class="{'disabled': isDisabled, 'err': !!isErrorOther}"
      @keydown="() => $emit('onTextAreaKeydown')"
      :disabled="isDisabled"
      :ref="refTxt"
      :name="refTxt+'_other'"
    >
    </textarea>
  </v-radio-group>
</template>
<style lang="scss" scoped>
textarea.disabled {
  opacity: 0.5;
  background: #d7dadb;
}
.inps-err-txt {
  position: relative;
  margin-left: 10px;
  color: #f00;
  font-size: 18px;
  font-weight: normal;
}
@media only screen and (max-width: 767px) {
  .ins-radio-group {
    width: 100%;
  }
  .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 8px;
  }
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
