<template>
<div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_MAKER') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <div class="form-wrap-input-selectWrap">
        <SelectMaker
          name="maker"
          :model="commonData.maker"
          :options="constants.SELECT_MAKER || []"
          :isError="errors.maker"
          @onChangeModelValue="(val) => {errors.maker = null; commonData.maker=val}"
        />
      </div>
      <p class="inps-err-txt">{{errors.maker}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>

  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_BODY_TYPE') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input">
      <div v-bind:class="{'err': errors.body_types}" ref="body_type" name="body_types">
        <template v-for="typ in (constants.SELECT_BODY_TYPE || [])">
          <v-checkbox
            v-model="inspectionStore.bodyTypes[typ.value]"
            @change="() => errors.body_types=null"
            :label="typ.label"
            :key="typ.value"
          ></v-checkbox>
          <template v-if="typ.value3">
            <div class="body_type_break mb-4" :key="`bottom_${typ.value}`">{{ typ.value3 }}</div>
          </template>
        </template>

      </div>
    </div>
  </div>

  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_BIKE_TYPE') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input">
      <input type="text" class="full" v-model="commonData.bike_type" v-bind:class="{'err': errors.bike_type}" @keydown="errors.bike_type=null" ref="bike_type" name="bike_type">
      <p class="inps-err-txt full">{{errors.bike_type}}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_FIRST_INSPECTION') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.first_inspection"
        :modelText="commonData.first_inspection_other"
        :options="constants.SELECT_FUMEI_NASI || []"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'first_inspection')"
        @onChangeModelTextValue="(val) => commonData.first_inspection_other = val"
        :isErrorRadio="errors.first_inspection"
        :isErrorOther="errors.first_inspection_other"
        @onTextAreaKeydown="() => errors.first_inspection=null"
        refTxt="first_inspection"
        name="first_inspection"
      />
      <p class="inps-err-txt">{{errors.first_inspection || errors.first_inspection_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <div class="form-wrap-tit d-flex align-start">
      <p style="font-size: 18px;font-weight: bold;color: #000;">
        {{ $t('INSPECTION_STEP2_INSPEC_EXPIRE') }}
        <br class="only_pc" />
        {{ $t('INSPECTION_STEP2_INSPEC_EXPIRE_2') }}
      </p>
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </div>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.inspec_expire"
        :modelText="commonData.inspec_expire_other"
        :options="constants.SELECT_FUMEI_NASI || []"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'inspec_expire')"
        @onChangeModelTextValue="(val) => commonData.inspec_expire_other = val"
        :isErrorRadio="errors.inspec_expire"
        :isErrorOther="errors.inspec_expire_other"
        @onTextAreaKeydown="() => errors.inspec_expire=null"
        refTxt="inspec_expire"
        name="inspec_expire"
      />
      <p class="inps-err-txt">{{errors.inspec_expire || errors.inspec_expire_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_CUBIC_CAPACITY_1') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
          :model="commonData.cubic_capacity"
          :modelText="commonData.cubic_capacity_other"
          :options="constants.SELECT_INPUT_OPTION || []"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'cubic_capacity')"
          @onChangeModelTextValue="(val) => commonData.cubic_capacity_other = val"
          :isErrorRadio="errors.cubic_capacity"
          :isErrorOther="errors.cubic_capacity_other"
          @onTextAreaKeydown="() => errors.cubic_capacity=null"
          appendText="cc"
          refTxt="cubic_capacity"
          name="cubic_capacity"
        />
      <p class="inps-err-txt">{{errors.cubic_capacity || errors.cubic_capacity_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_FRAME_NO') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
          :model="commonData.frame_no"
          :modelText="commonData.frame_no_other"
          :options="constants.SELECT_INPUT_OPTION || []"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'frame_no')"
          @onChangeModelTextValue="(val) => commonData.frame_no_other = val"
          :isErrorRadio="errors.frame_no"
          :isErrorOther="errors.frame_no_other"
          @onTextAreaKeydown="() => errors.frame_no=null"
          refTxt="frame_no"
          name="frame_no"
        />
      <p class="inps-err-txt">{{errors.frame_no || errors.frame_no_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_MODEL') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
          :model="commonData.model"
          :modelText="commonData.model_other"
          :options="constants.SELECT_INPUT_OPTION || []"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'model')"
          @onChangeModelTextValue="(val) => commonData.model_other = val"
          :isErrorRadio="errors.model"
          :isErrorOther="errors.model_other"
          :errors="errors"
          @onTextAreaKeydown="() => errors.model=null"
          refTxt="model"
          name="model"
        />
      <p class="inps-err-txt">{{errors.model || errors.model_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_ENGINE_TYPE_1') }}<br />
      {{ $t('INSPECTION_STEP2_ENGINE_TYPE_2') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
          :model="commonData.engine_type"
          :modelText="commonData.engine_type_other"
          :options="constants.SELECT_INPUT_OPTION || []"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'engine_type')"
          @onChangeModelTextValue="(val) => commonData.engine_type_other = val"
          :isErrorRadio="errors.engine_type"
          :isErrorOther="errors.engine_type_other"
          @onTextAreaKeydown="() => errors.engine_type=null"
          refTxt="engine_type"
          name="engine_type"
        />
      <p class="inps-err-txt">{{errors.engine_type || errors.engine_type_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_FUEL_TYPE') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <div class="form-wrap-input-selectWrap">
        <select
          v-model="commonData.fuel_type"
          v-bind:class="{'err': errors.fuel_type}"
          @change="() => errors.fuel_type=null"
          ref="fuel_type"
          name="fuel_type"
        >
          <option :value="null">{{ $t('INSPECTION_STEP2_FUEL_TYPE_SELECT') }}</option>
          <option v-for="val in (constants.SELECT_FUEL_TYPE || [])" :value="val.value" :key="val.value">{{val.label}}</option>
        </select>
      </div>
      <p class="inps-err-txt">{{errors.fuel_type}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="mt-5">
    <p class="error--text">{{$t('INSPECTION_STEP2_NOTICE')}}</p>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_MILEAGE') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
          :model="commonData.mileage"
          :modelText="commonData.mileage_other"
          :options="constants.SELECT_INPUT_OPTION || []"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'mileage')"
          @onChangeModelTextValue="(val) => commonData.mileage_other = val"
          :isErrorRadio="errors.mileage"
          :isErrorOther="errors.mileage_other"
          @onTextAreaKeydown="() => errors.mileage=null"
          refTxt="mileage"
          name="mileage"
        />
      <p class="inps-err-txt">{{errors.mileage || errors.mileage_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_COLOR') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
          :model="commonData.color"
          :modelText="commonData.color_other"
          :options="constants.SELECT_INPUT_OPTION || []"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'color')"
          @onChangeModelTextValue="(val) => commonData.color_other = val"
          :isErrorRadio="errors.color"
          :isErrorOther="errors.color_other"
          @onTextAreaKeydown="() => errors.color=null"
          refTxt="color"
          name="color"
        />
      <p class="inps-err-txt">{{errors.color || errors.color_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_METER') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.meter_speed"
        :modelText="commonData.meter_speed_other"
        :options="constants.SELECT_YES_NO || []"
        :label="$t('INSPECTION_STEP2_METER_SPEED')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'meter_speed')"
        @onChangeModelTextValue="(val) => commonData.meter_speed_other = val"
        :isErrorRadio="errors.meter_speed"
        :isErrorOther="errors.meter_speed_other"
        @onTextAreaKeydown="() => errors.meter_speed=null"
        refTxt="meter_speed"
        name="meter_speed"
      />
      <p class="inps-err-txt">{{errors.meter_speed || errors.meter_speed_other}}</p>
      <v-spacer></v-spacer>
    </div>
    <p class="form-wrap-tit"></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.meter_taco"
        :modelText="commonData.meter_taco_other"
        :options="constants.SELECT_YES_NO_TACO || []"
        :label="$t('INSPECTION_STEP2_METER_TACO')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'meter_taco')"
        @onChangeModelTextValue="(val) => commonData.meter_taco_other = val"
        :isErrorRadio="errors.meter_taco"
        :isErrorOther="errors.meter_taco_other"
        @onTextAreaKeydown="() => errors.meter_taco=null"
        refTxt="meter_taco"
        name="meter_taco"
      />
      <p class="inps-err-txt">{{errors.meter_taco || errors.meter_taco_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP2_ENGINE_SERIAL') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
          :model="commonData.engine_serial"
          :modelText="commonData.engine_serial_other"
          :options="constants.SELECT_INPUT_OPTION || []"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'engine_serial')"
          @onChangeModelTextValue="(val) => commonData.engine_serial_other = val"
          :isErrorRadio="errors.engine_serial"
          :isErrorOther="errors.engine_serial_other"
          @onTextAreaKeydown="() => errors.engine_serial=null"
          refTxt="engine_serial"
          name="engine_serial"
        />
      <p class="inps-err-txt">{{errors.engine_serial || errors.engine_serial_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_HORN') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
          :model="commonData.horn"
          :modelText="commonData.horn_other"
          :options="constants.SELECT_YES_NO || []"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'horn')"
          @onChangeModelTextValue="(val) => commonData.horn_other = val"
          :isErrorRadio="errors.horn"
          :isErrorOther="errors.horn_other"
          @onTextAreaKeydown="() => errors.horn=null"
          refTxt="horn"
          name="horn"
        />
      <p class="inps-err-txt">{{errors.horn || errors.horn_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_HEAD_LIGHT') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
          :model="commonData.headlight_hi"
          :modelText="commonData.headlight_hi_other"
          :options="constants.SELECT_YES_NO || []"
          :label="$t('INSPECTION_STEP2_HEAD_LIGHT_HI')"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'headlight_hi')"
          @onChangeModelTextValue="(val) => commonData.headlight_hi_other = val"
          :isErrorRadio="errors.headlight_hi"
          :isErrorOther="errors.headlight_hi_other"
          @onTextAreaKeydown="() => errors.headlight_hi=null"
          refTxt="headlight_hi"
          name="headlight_hi"
        />
      <p class="inps-err-txt">{{errors.headlight_hi || errors.headlight_hi_other}}</p>
      <v-spacer></v-spacer>
    </div>
    <p class="form-wrap-tit"></p>
    <div class="form-wrap-input d-flex">
        <RadioGroup
          :model="commonData.headlight_lo"
          :modelText="commonData.headlight_lo_other"
          :options="constants.SELECT_YES_NO || []"
          :label="$t('INSPECTION_STEP2_HEAD_LIGHT_LO')"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'headlight_lo')"
          @onChangeModelTextValue="(val) => commonData.headlight_lo_other = val"
          :isErrorRadio="errors.headlight_lo"
          :isErrorOther="errors.headlight_lo_other"
          @onTextAreaKeydown="() => errors.headlight_lo=null"
          refTxt="headlight_lo"
          name="headlight_lo"
        />
      <p class="inps-err-txt">{{errors.headlight_lo || errors.headlight_lo_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_WINKER') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.winker_fl"
        :modelText="commonData.winker_fl_other"
        :options="constants.SELECT_YES_NO || []"
        :label="$t('INSPECTION_STEP2_WINKER_FL')"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'winker_fl')"
        @onChangeModelTextValue="(val) => commonData.winker_fl_other = val"
        :isErrorRadio="errors.winker_fl"
        :isErrorOther="errors.winker_fl_other"
        @onTextAreaKeydown="() => errors.winker_fl=null"
        refTxt="winker_fl"
        name="winker_fl"
      />
      <p class="inps-err-txt">{{errors.winker_fl || errors.winker_fl_other}}</p>
      <v-spacer></v-spacer>
    </div>
    <p class="form-wrap-tit"></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.winker_fr"
        :modelText="commonData.winker_fr_other"
        :options="constants.SELECT_YES_NO || []"
        :label="$t('INSPECTION_STEP2_WINKER_FR')"
          @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'winker_fr')"
        @onChangeModelTextValue="(val) => commonData.winker_fr_other = val"
        :isErrorRadio="errors.winker_fr"
        :isErrorOther="errors.winker_fr_other"
        @onTextAreaKeydown="() => errors.winker_fr=null"
        refTxt="winker_fr"
        name="winker_fr"
      />
      <p class="inps-err-txt">{{errors.winker_fr || errors.winker_fr_other}}</p>
      <v-spacer></v-spacer>
    </div>
    <p class="form-wrap-tit"></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.winker_rl"
        :modelText="commonData.winker_rl_other"
        :options="constants.SELECT_YES_NO || []"
        :label="$t('INSPECTION_STEP2_WINKER_RL')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'winker_rl')"
        @onChangeModelTextValue="(val) => commonData.winker_rl_other = val"
        :isErrorRadio="errors.winker_rl"
        :isErrorOther="errors.winker_rl_other"
        @onTextAreaKeydown="() => errors.winker_rl=null"
        refTxt="winker_rl"
        name="winker_rl"
      />
      <p class="inps-err-txt">{{errors.winker_rl || errors.winker_rl_other}}</p>
      <v-spacer></v-spacer>
    </div>
    <p class="form-wrap-tit"></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.winker_rr"
        :modelText="commonData.winker_rr_other"
        :options="constants.SELECT_YES_NO || []"
        :label="$t('INSPECTION_STEP2_WINKER_RR')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'winker_rr')"
        @onChangeModelTextValue="(val) => commonData.winker_rr_other = val"
        :isErrorRadio="errors.winker_rr"
        :isErrorOther="errors.winker_rr_other"
        @onTextAreaKeydown="() => errors.winker_rr=null"
        refTxt="winker_rr"
        name="winker_rr"
      />
      <p class="inps-err-txt">{{errors.winker_rr || errors.winker_rr_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP2_TAIL_LIGHT') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.tail_light_small"
        :modelText="commonData.tail_light_small_other"
        :options="constants.SELECT_YES_NO || []"
        :label="$t('INSPECTION_STEP2_TAIL_LIGHT_SMALL')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'tail_light_small')"
        @onChangeModelTextValue="(val) => commonData.tail_light_small_other = val"
        :isErrorRadio="errors.tail_light_small"
        :isErrorOther="errors.tail_light_small_other"
        @onTextAreaKeydown="() => errors.tail_light_small=null"
        refTxt="tail_light_small"
        name="tail_light_small"
      />
      <p class="inps-err-txt">{{errors.tail_light_small || errors.tail_light_small_other}}</p>
      <v-spacer></v-spacer>
    </div>
    <p class="form-wrap-tit"></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.tail_light_break"
        :modelText="commonData.tail_light_break_other"
        :options="constants.SELECT_YES_NO || []"
        :label="$t('INSPECTION_STEP2_TAIL_LIGHT_BREAK')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'tail_light_break')"
        @onChangeModelTextValue="(val) => commonData.tail_light_break_other = val"
        :isErrorRadio="errors.tail_light_break"
        :isErrorOther="errors.tail_light_break_other"
        @onTextAreaKeydown="() => errors.tail_light_break=null"
        refTxt="tail_light_break"
        name="tail_light_break"
      />
      <p class="inps-err-txt">{{errors.tail_light_break || errors.tail_light_break_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP2_BATTERY_HEALTH') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.battery_health"
        :modelText="commonData.battery_health_other"
        :options="constants.SELECT_BATTERY_HEALTH || []"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'battery_health')"
        @onChangeModelTextValue="(val) => commonData.battery_health_other = val"
        :isErrorRadio="errors.battery_health"
        :isErrorOther="errors.battery_health_other"
        @onTextAreaKeydown="() => errors.battery_health=null"
        refTxt="battery_health"
        name="battery_health"
      />
      <p class="inps-err-txt">{{errors.battery_health || errors.battery_health_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP2_RADIATOR_LEAK') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.radiator_leak"
        :modelText="commonData.radiator_leak_other"
        :options="constants.SELECT_RADIATOR_LEAK || []"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'radiator_leak')"
        @onChangeModelTextValue="(val) => commonData.radiator_leak_other = val"
        :isErrorRadio="errors.radiator_leak"
        :isErrorOther="errors.radiator_leak_other"
        @onTextAreaKeydown="() => errors.radiator_leak=null"
        refTxt="radiator_leak"
        name="radiator_leak"
      />
      <p class="inps-err-txt">{{errors.radiator_leak || errors.radiator_leak_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_ENGINE_START_STATUS') }}
      <br class="only_pc">
      {{ $t('INSPECTION_STEP2_ENGINE_START_STATUS_2') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.engine_white_smoke"
        :modelText="commonData.engine_white_smoke_other"
        :options="constants.SELECT_ARI_NASI || []"
        :label="$t('INSPECTION_STEP2_WHITE_SMOKE')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'engine_white_smoke')"
        @onChangeModelTextValue="(val) => commonData.engine_white_smoke_other = val"
        :isErrorRadio="errors.engine_white_smoke"
        :isErrorOther="errors.engine_white_smoke_other"
        @onTextAreaKeydown="() => errors.engine_white_smoke_other=null"
        refTxt="engine_white_smoke"
        name="engine_white_smoke"
      >
        <template v-slot:notice>
          <span class="text-red">※2ストの場合は「その他」を選択し2ストを入力してください。</span>
        </template>
      </RadioGroup>
      <p class="inps-err-txt">{{errors.engine_white_smoke || errors.engine_white_smoke_other}}</p>
      <v-spacer></v-spacer>
    </div>
    <p class="form-wrap-tit"></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.engine_noise"
        :modelText="commonData.engine_noise_other"
        :options="constants.SELECT_ARI_NASI || []"
        :label="$t('INSPECTION_STEP2_ENGINE_NOISE')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'engine_noise')"
        @onChangeModelTextValue="(val) => commonData.engine_noise_other = val"
        :isErrorRadio="errors.engine_noise"
        :isErrorOther="errors.engine_noise_other"
        @onTextAreaKeydown="() => errors.engine_noise_other=null"
        refTxt="engine_noise"
        name="engine_noise"
      />
      <p class="inps-err-txt">{{errors.engine_noise || errors.engine_noise_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP2_FRONT_FORK_STATUS') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.front_fork_strain"
        :modelText="commonData.front_fork_strain_other"
        :options="constants.SELECT_ARI_NASI || []"
        :label="$t('INSPECTION_STEP2_FFORK_STRAIN')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'front_fork_strain')"
        @onChangeModelTextValue="(val) => commonData.front_fork_strain_other = val"
        :isErrorRadio="errors.front_fork_strain"
        :isErrorOther="errors.front_fork_strain_other"
        @onTextAreaKeydown="() => errors.front_fork_strain_other=null"
        refTxt="front_fork_strain"
        name="front_fork_strain"
      />
      <p class="inps-err-txt">{{errors.front_fork_strain || errors.front_fork_strain_other}}</p>
      <v-spacer></v-spacer>
    </div>
    <p class="form-wrap-tit"></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.front_fork_rusty"
        :modelText="commonData.front_fork_rusty_other"
        :options="constants.SELECT_ARI_NASI || []"
        :label="$t('INSPECTION_STEP2_RUSTY')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'front_fork_rusty')"
        @onChangeModelTextValue="(val) => commonData.front_fork_rusty_other = val"
        :isErrorRadio="errors.front_fork_rusty"
        :isErrorOther="errors.front_fork_rusty_other"
        @onTextAreaKeydown="() => errors.front_fork_rusty_other=null"
        refTxt="front_fork_rusty"
        name="front_fork_rusty"
      />
      <p class="inps-err-txt">{{errors.front_fork_rusty || errors.front_fork_rusty_other}}</p>
      <v-spacer></v-spacer>
    </div>
    <p class="form-wrap-tit"></p>
    <div class="form-wrap-input d-flex">
      <RadioGroup
        :model="commonData.front_fork_oil_leak"
        :modelText="commonData.front_fork_oil_leak_other"
        :options="constants.SELECT_ARI_NASI || []"
        :label="$t('INSPECTION_STEP2_OIL_LEAK')"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'front_fork_oil_leak')"
        @onChangeModelTextValue="(val) => commonData.front_fork_oil_leak_other = val"
        :isErrorRadio="errors.front_fork_oil_leak"
        :isErrorOther="errors.front_fork_oil_leak_other"
        @onTextAreaKeydown="() => errors.front_fork_oil_leak_other=null"
        refTxt="front_fork_oil_leak"
        name="front_fork_oil_leak"
      />
      <p class="inps-err-txt">{{errors.front_fork_oil_leak || errors.front_fork_oil_leak_other}}</p>
      <v-spacer></v-spacer>
    </div>
  </div>

  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_HANDLE') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input">
      <RadioGroupTextArea
        :model="commonData.handle"
        :modelText="commonData.handle_other"
        :options="constants.SELECT_TEXT_AREA || []"
        :isRequired="true"
        :errTxtNextToRadio="false"
        :isErrorRadio="errors.handle"
        :isErrorOther="errors.handle_other"
        @onTextAreaKeydown="() => errors.handle_other=null"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'handle')"
        @onChangeModelTextValue="(val) => commonData.handle_other = val"
        refTxt="handle"
        name="handle"
      />
      <p class="inps-err-txt">{{errors.handle || errors.handle_other}}</p>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_FRONT_BREAK') }}
      <br>{{ $t('INSPECTION_STEP2_FRONT_BREAK_2') }}
      <br>{{ $t('INSPECTION_STEP2_FRONT_BREAK_3') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input">
      <RadioGroupTextArea
        :model="commonData.front_break"
        :modelText="commonData.front_break_other"
        :options="constants.SELECT_TEXT_AREA || []"
        :isRequired="true"
        :isErrorRadio="errors.front_break"
        :isErrorOther="errors.front_break_other"
        @onTextAreaKeydown="() => errors.front_break_other=null"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'front_break')"
        @onChangeModelTextValue="(val) => commonData.front_break_other = val"
        refTxt="front_break"
        name="front_break"
      />
      <p class="inps-err-txt">{{errors.front_break || errors.front_break_other}}</p>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_REAR_BREAK') }}
      <br>
      {{ $t('INSPECTION_STEP2_REAR_BREAK_2') }}
      <br>
      {{ $t('INSPECTION_STEP2_REAR_BREAK_3') }}
      <em class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div class="form-wrap-input">
      <RadioGroupTextArea
        :model="commonData.rear_break"
        :modelText="commonData.rear_break_other"
        :options="constants.SELECT_TEXT_AREA || []"
        :isRequired="true"
        :isErrorRadio="errors.rear_break"
        :isErrorOther="errors.rear_break_other"
        @onTextAreaKeydown="() => errors.rear_break_other=null"
        @onChangeModelValue="(val) => inspectionStore.onChangeModelValue(val, 'rear_break')"
        @onChangeModelTextValue="(val) => commonData.rear_break_other = val"
        refTxt="rear_break"
        name="rear_break"
      />
      <p class="inps-err-txt">{{errors.rear_break || errors.rear_break_other}}</p>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP2_ENGINE_CONDITION') }}</p>
    <div class="form-wrap-input">
      <textarea
        type="text"
        class="full"
        v-model="commonData.engine_condition"
        v-bind:class="{'err': errors.engine_condition}"
        @keydown="errors.engine_condition=null"
        ref="engine_condition"
      >
      </textarea>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP2_TIRE_CONDITION') }}</p>
    <div class="form-wrap-input">
      <textarea
        type="text"
        class="full"
        v-model="commonData.tire_condition"
        v-bind:class="{'err': errors.tire_condition}"
        @keydown="errors.tire_condition=null"
        ref="tire_condition"
      >
      </textarea>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP2_MODIFICATION') }}</p>
    <div class="form-wrap-input">
      <textarea
        type="text"
        class="full"
        v-model="commonData.modification"
        v-bind:class="{'err': errors.modification}"
        @keydown="errors.modification=null"
        ref="modification"
      >
      </textarea>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_SCRATCH') }}
    </p>
    <div class="form-wrap-input">
      <textarea
        type="text"
        class="full"
        v-model="commonData.scratch"
        v-bind:class="{'err': errors.scratch}"
        @keydown="errors.scratch=null"
        ref="scratch"
      >
      </textarea>
      <!-- <p class="err-txt">{{errors.scratch}}</p> -->
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_OTHER_MEMO') }}
    </p>
    <div class="form-wrap-input">
      <textarea
        type="text"
        class="full"
        v-model="commonData.other_memo"
        v-bind:class="{'err': errors.other_memo}"
        @keydown="errors.other_memo=null"
        ref="other_memo"
      >
      </textarea>
      <!-- <p class="err-txt">{{errors.other_memo}}</p> -->
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_COMMENT') }}
    </p>
    <div class="form-wrap-input">
      <textarea
        type="text"
        class="full"
        v-model="inspectionStore.comment"
        v-bind:class="{'err': errors.comment}"
        @keydown="errors.comment=null"
        ref="comment"
      >
      </textarea>
      <!-- <p class="err-txt">{{errors.comment}}</p> -->
    </div>
  </div>

  <div class="textarea-wrap">
    <p class="form-wrap-tit form-privacy">
      {{$t('INSPECTION_STEP2_AGREEMENT')}}
      <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
    </p>
    <div class="form-wrap-input form-privacy">
      <label for="rule-chk">
        <input type="checkbox" id="rule-chk" class="checkbox-input" @click="inspectionStore.ruleCheckClick" required>
        <span class="checkbox-parts" v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></span>
      </label>
    </div>
  </div>

</div>
</template>
<script>
import useInspection from '@/stores/useInspection'
import useLoading from '@/stores/useLoading'
import {computed, defineComponent} from '@vue/composition-api'
import DatePicker from '../../RegisterMember/DatePicker.vue'
import RadioGroup from './RadioGroup'
import RadioGroupSlot from './RadioGroupSlot'
import RadioGroupTextArea from './RadioGroupTextArea'
import SelectMaker from './SelectMaker'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const inspectionStore = useInspection()
    const constants = computed(() => inspectionStore.constants)

    return {
      loadingStore,
      inspectionStore,
      constants
    }
  },
  components : {
    RadioGroup,
    RadioGroupSlot,
    RadioGroupTextArea,
    DatePicker,
    SelectMaker
  },
  props      : {},
  data() {
    return {
    }
  },
  computed : {
    commonData() {
      return this.inspectionStore.commonData
    },
    errors() {
      return this.inspectionStore.errors
    }
  },
  methods : {
  },
  /*
   * Watch : {
   *   errors(value) {
   *     const keys = Object.keys(value)
   *     if (keys.length > 0) {
   *       const refkey = keys[0]
   *       if (value[keys[0]] && this.$refs[refkey]) {
   *         this.$refs[refkey].focus()
   *       }
   *     }
   *   }
   * }
   */
})
</script>

<style scoped lang="scss">
  .ipt-file-box {
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 768px) {
    p.err-txt {
      position: absolute;
      top: 50%;
      left: 450px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      max-width: 290px;
    }
    .textarea-content {
      background-color: white;
      width:430px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    p.err-txt {
        position: static;
        -webkit-transform: none;
        transform: none;
        max-width: 100%;
        margin-top: 5px;
    }
  }

  .with-int-no {
    width: 305px !important;
  }
  select.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  label.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  .bold-text {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
</style>
<style lang="scss" scoped>
  .est-form {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #main .exhibit #entry-form .form-wrap {
    align-items: start !important;
  }
  .form-privacy {
    width: auto !important;
  }
  .form-wrap-input .err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  #main .exhibit #entry-form .form-wrap-input .inps-err-txt {
    position: relative;
    margin-left: 10px;
    color: #f00
  }
  #main .exhibit #entry-form .form-wrap-input-selectWrap,
  #main .exhibit #entry-form .form-wrap .ipt-file-selectWrap {
    width: calc(100% - 240px);
  }
  .body_type_break {
    color: #E70012;
  }

  @media only screen and (max-width: 767px) {

    #main .exhibit #entry-form .form-wrap-input-selectWrap,
    #main .exhibit #entry-form .form-wrap .ipt-file-selectWrap {
      width: 100% !important;
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
