<template>
<div class="wrap1160 exhibit-wrap">
  <h1>{{ $t('INSPECTION_FORM_TITLE') }}</h1>
  <div class="sub-title">
    <p>・お時間かかる場合がありますのでページ下部にある一時保存をしながら入力してください。</p>
    <p>・「なし」の場合で「なし」が選択肢にない場合は「その他」を選択し「なし」を入力してください。</p>
  </div>
  <form id="entry-form">
    <div v-if="requestResultData.judge_status === 1 || requestResultData.judge_status === 2" class="mb-5">
        <h2>{{ requestResultData.request_category_name + $t('REQUEST_RESULT_FROM_TITLE') }}</h2>
        <RequestResultDetailPreview
          :isPreview="true"
          :requestResultData="requestResultData"
          :judgeStatusPreview="true"
        />
      </div>

    <div class="entry-formInner bgLGray">
      <Step1 />

      <item-images
        :type="1"
        class="fileupCol image-item"
        :title="$t('INSPECTION_STEP1_ITEM_IMAGES')"
        isRequired
        :isError="errors.itemImageRequired"
        :getPreviewData="inspectionStore.getPreviewData"
        :images="itemImages"
        :errors="errors"
        ref="itemImageRequired"
        name="itemImageRequired"
        />
      <item-images
        :type="2"
        class="fileupAny image-item"
        :title="$t('INSPECTION_STEP1_OPTIONAL_IMAGES')"
        :getPreviewData="inspectionStore.getPreviewData"
        :images="optionalImages"
        :errors="errors"
        ref="optinalImageRequired"
        name="optinalImageRequired"
        />

      <document-files
        name="document_file"
        class="fileupAny image-item"
        :commonData="commonData"
        :documentFiles="documentFiles"
        :errors="errors"
        :addDocumentFile="inspectionStore.addDocumentFile"
      />

      <Step2 />
      <SubmitButton />
    </div>
  </form>
</div>
</template>

<script>
import useInspection from '@/stores/useInspection'
import useLoading from '@/stores/useLoading'
import useRequestResultPreview from '@/stores/useRequestResultPreview'
import {defineComponent} from '@vue/composition-api'
import DocumentFiles from './parts/DocumentFiles'
import ItemImages from './parts/ItemImages'
import RequestResultDetailPreview from './parts/RequestResultDetailPreview'
import Step1 from './parts/Step1'
import Step2 from './parts/Step2'
import SubmitButton from './parts/SubmitButton'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const inspectionStore = useInspection()
    const resultPreviewStore = useRequestResultPreview()
    return {
      loadingStore,
      inspectionStore,
      resultPreviewStore
    }
  },
  components : {
    Step1,
    Step2,
    SubmitButton,
    ItemImages,
    DocumentFiles,
    RequestResultDetailPreview
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
    }
  },
  computed : {
    errors() {
      return this.inspectionStore.errors
    },
    commonData() {
      return this.inspectionStore.commonData
    },
    identityFiles() {
      return this.inspectionStore.identityFiles
    },
    bodyTypes() {
      return this.inspectionStore.bodyTypes
    },
    itemImages() {
      return this.inspectionStore.itemImages
    },
    optionalImages() {
      return this.inspectionStore.optionalImages
    },
    documentFiles() {
      return this.inspectionStore.documentFiles
    },
    constants() {
      return this.inspectionStore.constants
    },
    editFlag() {
      return this.inspectionStore.editFlag
    },
    requestResultData() {
      return this.resultPreviewStore.requestResultData
    },
  },
  methods : {
  },
  mounted() {
  },
  created() {
  },
  watch : {
    '$i18n.locale'() {
      // This.getConstants()
    },
    /*
     * Errors(value) {
     *   const keys = Object.keys(value)
     *   if (keys.length > 0) {
     *     const refkey = keys[0]
     *     if (value[keys[0]] && this.$refs[refkey]) {
     *       this.$refs[refkey].focus()
     *     }
     *   }
     * }
     */
  }
})
</script>
<style scoped lang="scss">
  .ipt-file-box {
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 768px) {
    p.err-txt {
      position: absolute;
      top: 50%;
      left: 450px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      max-width: 290px;
    }
    .textarea-content {
      background-color: white;
      width:430px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    p.err-txt {
        position: static;
        -webkit-transform: none;
        transform: none;
        max-width: 100%;
        margin-top: 5px;
    }
  }

  .with-int-no {
    width: 305px !important;
  }
  select.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  label.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  .bold-text {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
</style>
<style lang="scss" scoped>
  .est-form {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
<style lang="scss" scoped>
  .image-item {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .sub-title {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .sub-title p {
    color: #364A81;
    font-size: 1em;
    font-weight: 600;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
